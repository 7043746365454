<template>
  <table>
    <thead id="tableHead">
      <tr>
        <th class="ticketCheckbox"><input type="checkbox" id="titleCheckbox" @change="checkAll"></th>

        <th class="ticketId" @click="sortByField('ticketId')">
          <div class="parent">
            <div class="left">
              {{ this.$t("ticketList.id") }}
            </div>
            <div class="right">
              <div class="top">
                  <span class="fa fa-sort-up"
                        :class="equalsFields('ticketId') ? (sortAndOrder() ? 'inactive' : 'active') : 'inactive'">
                  </span>
              </div>
              <div class="bottom">
                  <span class="fa fa-sort-down"
                        :class="equalsFields('ticketId') ? (sortAndOrder() ? 'active' : 'inactive') : 'inactive'">
                  </span>
              </div>
            </div>
          </div>
        </th>

        <th class="ticketType" @click="sortByField('ticketType', 'ticketType')">
          <div class="parent">
            <div class="left">
              {{ this.$t("ticketList.ticketType") }}
            </div>
            <div class="right">
              <div class="top">
                <span class="fa fa-sort-up"
                      :class="equalsFields('ticketType') ? (sortAndOrder() ? 'inactive' : 'active') : 'inactive'">
                </span>
              </div>
              <div class="bottom">
                <span class="fa fa-sort-down"
                      :class="equalsFields('ticketType') ? (sortAndOrder() ? 'active' : 'inactive') : 'inactive'">
                </span>
              </div>
            </div>
          </div>
        </th>

        <th class="ticketStatus" @click="sortByField('ticketStatus', 'ticketStatus')">
          <div class="parent">
            <div class="left">
              {{ this.$t("ticketList.status") }}
            </div>
            <div class="right">
              <div class="top">
                <span class="fa fa-sort-up"
                      :class="equalsFields('ticketStatus') ? (sortAndOrder() ? 'inactive' : 'active') : 'inactive'">
                </span>
              </div>
              <div class="bottom">
                <span class="fa fa-sort-down"
                      :class="equalsFields('ticketStatus') ? (sortAndOrder() ? 'active' : 'inactive') : 'inactive'">
                </span>
              </div>
            </div>
          </div>
        </th>

        <th class="ticketUserName" @click="sortByField('userName')">
            <div class="parent">
                <div class="left">
                    {{ this.$t("ticketList.userName") }}
                </div>
                <div class="right">
                    <div class="top">
                  <span class="fa fa-sort-up"
                        :class="equalsFields('userName') ? (sortAndOrder() ? 'inactive' : 'active') : 'inactive'">
                  </span>
                    </div>
                    <div class="bottom">
                  <span class="fa fa-sort-down"
                        :class="equalsFields('userName') ? (sortAndOrder() ? 'active' : 'inactive') : 'inactive'">
                  </span>
                    </div>
                </div>
            </div>
        </th>

        <th class="ticketAssigned" @click="sortByField('assigned')">
          <div class="parent">
            <div class="left">
              {{ this.$t("ticketList.assigned") }}
            </div>
            <div class="right">
              <div class="top">
                  <span class="fa fa-sort-up"
                        :class="equalsFields('assigned') ? (sortAndOrder() ? 'inactive' : 'active') : 'inactive'">
                  </span>
              </div>
              <div class="bottom">
                  <span class="fa fa-sort-down"
                        :class="equalsFields('assigned') ? (sortAndOrder() ? 'active' : 'inactive') : 'inactive'">
                  </span>
              </div>
            </div>
          </div>
        </th>

        <th class="ticketDate" @click="sortByField('createdDate')">
          <div class="parent">
            <div class="left">
              {{ this.$t("ticketList.created") }}
            </div>
            <div class="right">
              <div class="top">
                  <span class="fa fa-sort-up"
                        :class="equalsFields('createdDate') ? (sortAndOrder() ? 'inactive' : 'active') : 'inactive'">
                  </span>
              </div>
              <div class="bottom">
                  <span class="fa fa-sort-down"
                        :class="equalsFields('createdDate') ? (sortAndOrder() ? 'active' : 'inactive') : 'inactive'">
                  </span>
              </div>
            </div>
          </div>
        </th>
      </tr>
    </thead>
    <tbody>
    <SoloTicket ref="ticketList"
                v-for="ticket of tickets" :key="ticket.ticketId"
                v-bind:ticket="ticket"
                @addRaw="changeDiv"
    />
    </tbody>
  </table>
</template>

<script>
import SoloTicket from "./SoloTicket";
import $ from 'jquery'
import App from "../../App";
import SideBarMenu from "@/components/SideBarMenu.vue";
import {GlobalLocalizationEventEmitter} from "@/i18n";

export default {
  props: ['tickets'],
  components: {
    SoloTicket
  },

  created() {
    GlobalLocalizationEventEmitter.$on("localizationChanged", () => {
      this.restoreOrders();
    })
  },

  beforeDestroy() {
    GlobalLocalizationEventEmitter.$off("localizationChanged", this);
  },

  mounted() {
    this.isRoleAdmin = SideBarMenu.methods.isRoleAdmin();
  },

  watch: {
    tickets: function () {
      this.restoreOrders();
    }
  },

  data() {
    return {
      checked: false,
      field: '',
      checkedTicketList: [],
      orderBy: false,
      clicked: false,
      isRoleAdmin: false,
    }
  },

  methods: {
    changeDiv() {
      var ticketList = this.$refs.ticketList;
      this.checkedTicketList = [];
      ticketList.forEach(element => {
        if (element.$refs.checkbox.checked) {
          this.checkedTicketList.push(element.ticket.ticketId);
        }
      });
      if (this.checkedTicketList.length > 0) {
        if (this.checkedTicketList.length === this.tickets.length) {
          $("#titleCheckbox").prop('checked', true);
        } else $("#titleCheckbox").prop('checked', false);
        this.$emit('setPanelVisible', this.checkedTicketList);
      } else {
        this.$emit('setHeaderVisible')
      }
    },

    checkAll() {
      var ticketList = this.$refs.ticketList;
      this.checkedTicketList = [];
      if ($('#titleCheckbox').prop('checked')) {
        ticketList.filter(element =>
            this.isRoleAdmin || (
                element.ticket.ticketStatus !== 'REJECTED'
             && element.ticket.ticketStatus !== 'CLOSED'
            )
        ).forEach(element => {
          element.$refs.checkbox.checked = true;
          this.checkedTicketList.push(element.ticket.ticketId)
        });
        this.$emit('setPanelVisible', this.checkedTicketList)
      } else {
        ticketList.forEach(element => {
          element.$refs.checkbox.checked = false;
        });
        this.$emit('setHeaderVisible')
      }
    },

    restoreOrders() {
      const field = window.localStorage.getItem("tickets.field");
      const order = window.localStorage.getItem("tickets.orderBy") === 'true';
      const locPrefix = window.localStorage.getItem("tickets.locPrefix");
      if (field != null) {
        this.field = field
        this.orderBy = order;
        this.sortByField(null, locPrefix);
      }
    },

    sortByField(field, localizedPrefix) {
      if (field != null) {
        if (Object.is(this.field, field)) {
          this.orderBy = !this.orderBy;
        } else {
          this.field = field;
          this.orderBy = true;
        }

        window.localStorage.setItem("tickets.field", this.field);
        window.localStorage.setItem("tickets.orderBy", this.orderBy);
        if (localizedPrefix != null) {
          window.localStorage.setItem("tickets.locPrefix", localizedPrefix);
        } else {
          window.localStorage.removeItem("tickets.locPrefix");
        }
      }

      if (localizedPrefix != null) {
        this.tickets.sort(
            App.methods.comparator(a => this.$t(localizedPrefix + '.' + a[this.field]), this.orderBy)
        );
      } else {
        this.tickets.sort(
            App.methods.comparator(a => a[this.field], this.orderBy)
        );
      }
    },

    sortAndOrder() {
      return this.orderBy;
    },

    equalsFields(field) {
      return this.field === field;
    },
  }
}
</script>

<style scoped lang="scss">
@import "../../../public/styles/tables.scss";

th, td {
  border-bottom: 1px solid grey;
}

th.cu {
  width: 23%;
}

th.ch {
  width: 1%;
}

</style>
