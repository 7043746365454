<template>
  <tr>
    <td class="ticketCheckboxTd">
      <input type="checkbox" @change="onChange" ref="checkbox" v-model="ticket.checkbox" :disabled="isDisabled()">
    </td>
    <td class="ticketIdTd" @click="getTicket(ticket.ticketId)">{{ ticket.ticketId }}</td>
    <td class="ticketTypeTd" @click="getTicket(ticket.ticketId)">{{ $t("ticketType." + ticket.ticketType) }}</td>
    <td class="ticketStatusTd" @click="getTicket(ticket.ticketId)">{{ $t("ticketStatus." + ticket.ticketStatus) }}</td>
    <td class="ticketUserNameTd" @click="getTicket(ticket.ticketId)">{{ ticket.userName }}</td>
    <td class="ticketAssignedTd" @click="getTicket(ticket.ticketId)">{{ ticket.assigned }}</td>
    <td class="ticketDateTd" @click="getTicket(ticket.ticketId)">
      {{ moment(ticket.createdDate).format('DD.MM.YYYY HH:mm') }}
    </td>
  </tr>
</template>

<script>
import moment from "moment";
import SideBarMenu from "@/components/SideBarMenu.vue";

export default {
  props: {
    ticket: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      isRoleAdmin: false
    }
  },

  mounted() {
    this.isRoleAdmin = SideBarMenu.methods.isRoleAdmin();
  },

  methods: {
    moment,
    onChange() {
      this.$emit('addRaw');
    },

    getTicket(id) {
      this.$router.push({name: 'ticketById', params: {'id': id.toString()}});
    },

    isDisabled() {
      return !this.isRoleAdmin && (
          this.ticket.ticketStatus === 'REJECTED'
       || this.ticket.ticketStatus === 'CLOSED'
      )
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../../public/styles/tables.scss";

.ticketUserNameTd, .ticketAssignedTd {
  overflow-wrap: anywhere;
}
</style>
