<template>
  <div id="TableContainer">
    <div>
      <Header table="tickets"
              ref="counter"
              @search="search"
              @changeDisplayed="changeDisplayed"
              @deleteAll="deleteTickets"
              v-bind:hidePanel="hidePanel"
      />
    </div>

    <vue-confirm-dialog></vue-confirm-dialog>

    <div id="table">
      <TicketList
          v-bind:tickets="changedTickets"
          :key="updateKey"
          @setHeaderVisible="setHeaderVisible"
          @setPanelVisible="setPanelVisible"
      />
    </div>
  </div>
</template>


<script>
import Header from "../Header";
import TicketList from "./TicketList";
import axios from "axios";
import Vue from 'vue';
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-default.css';
import Link from "../utils/Link";
//import App from "../../App";
import VueConfirmDialog from "vue-confirm-dialog";
import SideBarMenu from "@/components/SideBarMenu.vue";
import ui from "../utils/ui";
import moment from "moment";

Vue.use(VueConfirmDialog)
Vue.component('vue-confirm-dialog', VueConfirmDialog.default)

let tableHeaderPosition = [0];

export default {
  components: {
    TicketList, Header
  },

  data() {
    return {
      tickets: [],
      hidePanel: true,
      array: [],
      statuses: [],
      openTicketsFilter: true,
      anybodyAssignedFilter: false,
      sbActivity: true,
      loggedInUserId: -1,
      isRoleAdmin: false,
      updateKey: 0,
      lastQuery: (x) => x
    };
  },

  mounted() {
    this.isRoleAdmin = SideBarMenu.methods.isRoleAdmin();
    window.localStorage.removeItem("ticketData");
    this.loggedInUserId = window.localStorage.getItem("id");
    this.$emit("changeComponent", 'tickets');
    this.getTickets().then(() => {
      this.alignColumns();
    });
  },

  created: function () {
    window.addEventListener('scroll', this.scrolling);
    window.addEventListener('resize', this.windowResize);
  },

  destroyed() {
    window.removeEventListener('scroll', this.scrolling);
    window.addEventListener('resize', this.windowResize);
  },

  computed: {
    changedTickets: function () {
      const closedStatuses = ['CLOSED', 'REJECTED', 'NONE'];

      return this.tickets.filter(ticket =>
        (!this.openTicketsFilter || (closedStatuses.indexOf(ticket.ticketStatus) < 0))
        && (this.anybodyAssignedFilter ? true : (ticket.assignedId.toString() === this.loggedInUserId))
        && (this.lastQuery(ticket, this.isInclude))
      )
    }
  },

  methods: {
    moment,
    getTickets() {
      this.changeDisplayed();
      if (this.isRoleAdmin) {
        //if (Link.methods.parseJwt(Link.methods.getToken()).roles !== 'ADMIN')
        return this.getAllTickets();
      } else {
        return this.getUserTickets();
      }
    },

    search(qsearch) {
      this.lastQuery = qsearch;
    },

    fieldContain(query, field) {
      if (field !== null) {
        return field.toLowerCase().indexOf(query) !== -1;
      }
    },

    dateFieldContain(query, field, dateFormat) {
      if (field !== null) {
        return moment(field).format(dateFormat).indexOf(query) !== -1;
      }
    },

    isInclude(ticket, query) {
      return this.fieldContain(query, ticket.ticketId.toString())
          || this.fieldContain(query, this.$t("ticketType." + ticket.ticketType))
          || this.fieldContain(query, this.$t("ticketStatus." + ticket.ticketStatus))
          || this.fieldContain(query, ticket.userName)
          || this.fieldContain(query, ticket.assigned)
          || this.dateFieldContain(query, ticket.createdDate, 'DD.MM.YYYY HH:mm');
    },

    setPanelVisible(array) {
      this.array = array;
      this.$refs.counter.changeLabel(array.length, 'tickets');
      this.hidePanel = false;
    },

    setHeaderVisible() {
      this.hidePanel = true;
    },

    getAllTickets() {
      const headers = Link.methods.getHeaders()
      return axios.get(
        Link.methods.getTicketsUrl(),
        {headers}
      ).then(res => {
        this.tickets = res.data;
      }).catch(()=>{
        this.createToast(this.$t("mainTicket.ticketsNotFound"), "error")
      });
    },

    getUserTickets() {
      const headers = Link.methods.getHeaders();
      return axios.get(
        Link.methods.getUserTicketsUrl(),
        {headers}
      ).then(res => {
        this.tickets = res.data;
      }).catch(()=>{
        this.createToast(this.$t("mainTicket.ticketsNotFound"), "error")
      });
    },

    deleteTickets() {
      this.$confirm(
        {
          message: this.$t("mainTicket.deleteAllSelectedTickets"),
          button: {
            no: this.$t("mainTicket.confirmNo"),
            yes: this.$t("mainTicket.confirmYes")
          },
          callback: confirm => {
            if (confirm) {
              const headers = Link.methods.getHeaders();
              const promises = [];
              for (let i = 0; i < this.array.length; i++) {
                promises.push(axios.delete(Link.methods.getDeleteTicketsUrl(this.array[i]), {headers}))
              }
              Promise.all(promises).then( () => {
                if (promises.length === 1) {
                  this.createToast(this.$t('mainTicket.ticketDeleted'), "success")
                } else {
                  this.createToast(this.$t('mainTicket.ticketsDeleted', [promises.length]), "success");
                }
                this.array = [];
                this.getAllTickets();
                this.setHeaderVisible();
                this.updateKey += 1;
              }).catch( () => {
                this.createToast(this.$t("mainTicket.deleteTicketErrorToast", "error"),"error");
              })
            }
          }
        }
      )
    },

    createToast(msg, type) {
      Vue.use(VueToast);
      Vue.$toast.open({
        type: type,
        message: msg,
        position: "bottom-right"
      });
    },

    changeDisplayed() {
      let filter = window.localStorage.getItem("openTicketsFilter");
      if (filter != null) {
        this.openTicketsFilter = (filter === 'true');
      }
      filter = window.localStorage.getItem("anybodyAssignedTicketFilter");
      if (filter != null) {
        this.anybodyAssignedFilter = (filter === 'true');
      }
      if (!this.isRoleAdmin) {
        this.anybodyAssignedFilter = true
      }
    },

    windowResize() {
      this.alignColumns();
    },

    scrolling() {
      this.alignColumns();
      ui.methods.stickHeader(tableHeaderPosition);
    },

    alignColumns() {
      let rowWidth = 0;
      rowWidth += ui.methods.copyElementWidthIfDifferent('.ticketCheckboxTd', 'th.ticketCheckbox');
      rowWidth += ui.methods.copyElementWidthIfDifferent('.ticketIdTd',       'th.ticketId');
      rowWidth += ui.methods.copyElementWidthIfDifferent('.ticketUserNameTd', 'th.ticketUserName');
      rowWidth += ui.methods.copyElementWidthIfDifferent('.ticketAssignedTd', 'th.ticketAssigned');
      rowWidth += ui.methods.copyElementWidthIfDifferent('.ticketTypeTd',     'th.ticketType');
      rowWidth += ui.methods.copyElementWidthIfDifferent('.ticketStatusTd',   'th.ticketStatus');
      rowWidth += ui.methods.copyElementWidthIfDifferent('.ticketDateTd',     'th.ticketDate');
      ui.methods.copyElementWidthIfDifferent(rowWidth, '#tableHead');
    }
  }
}
</script>


<style scoped lang="scss">
@import "../../../public/styles/tables.scss";
</style>
